import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import DoneAll from '@mui/icons-material/DoneAll';
import Key from '@mui/icons-material/Key';
import Error from '@mui/icons-material/Error';
import { useMutation } from '@apollo/client';
import VerificationInput from 'react-verification-input';
import { Stack } from '@mui/system';
import { useGetUser, useSnackBars } from '../../hooks';
import { SEND_PHONE_VERIFICATION_CODE, VERIFY_PHONE } from '../../graphql';
import { WhatsappIcon } from '../../assets';
import Blinker from '../animations/Blink';

const PhoneVerificationTooltip = () => {
  const currentUser = useGetUser();
  const [phoneCode, setPhoneCode] = useState('');
  const [openMenu, setOpenMenu] = useState(false);
  const { addAlert } = useSnackBars();

  const [sendVerification] = useMutation(
    SEND_PHONE_VERIFICATION_CODE,
  );
  const [verifyPhone] = useMutation(
    VERIFY_PHONE,
    { onCompleted: () => {
      setPhoneCode('');
      setOpenMenu(false);
      addAlert({
        id: 'phone-verified',
        message: 'Tu teléfono ha sido verificado con éxito!',
      });
    } },
  );
  if (currentUser.isPhoneVerified) {
    return (
      <Tooltip title="Verificado">
        <DoneAll color="secondary" />
      </Tooltip>
    );
  }
  if (currentUser.phoneNumber?.length !== 12) {
    return (
      <Tooltip title="Este dato no está en el formato correcto">
        <Error color="error" />
      </Tooltip>
    );
  }
  if (currentUser.hasActivePhoneVerificationCode) {
    return (
      <>
        <Tooltip title="Ingresar código de verificación">
          <Blinker isBlinking CustomComponent={IconButton} onClick={() => setOpenMenu(true)} size="small">
            <Key color="primary" />
          </Blinker>
        </Tooltip>
        <Drawer
          open={openMenu}
          onClose={() => { setOpenMenu(false); }}
          anchor="right"
          PaperProps={{
            sx: { width: { xs: '100%', md: '50%', lg: '25%' } },
          }}
          sx={{ zIndex: 1350, position: 'absolute' }}
        >
          <Stack spacing={4} my={4} mx={2}>
            <Typography variant="h1" color="primary">
              Código de verificación
            </Typography>
            <Typography variant="caption">
              Ingresa aquí el código de verificación que te hemos envíado
              a tu cuenta de whatsapp, así podremos verificar
              que este número de teléfono te pertenece
            </Typography>
            <Grid alignSelf="center">
              <VerificationInput
                autoFocus
                value={phoneCode}
                onChange={(value) => setPhoneCode(value)}
                validChars="0-9"
              />
            </Grid>
            <Blinker
              softIntensity
              isBlinking={phoneCode.length === 6}
              disabled={phoneCode.length !== 6}
              variant="contained"
              CustomComponent={Button}
              sx={{ width: '50%', alignSelf: 'center', my: 24 }}
              onClick={() => verifyPhone({ variables: { code: phoneCode } })}
            >
              Ingresar
            </Blinker>
            <Tooltip title="Reenvienme el código de whatsapp" sx={{ alignSelf: 'center' }}>
              <Link variant="caption" alignSelf="center">
                ¿No recibiste el código?
              </Link>
            </Tooltip>
          </Stack>
        </Drawer>
      </>
    );
  }
  return (
    <Tooltip title="Enviar verificación por whatsapp">
      <IconButton onClick={sendVerification}>
        <img
          src={WhatsappIcon}
          alt="pdf-icon"
          style={{
            height: '22px',
          }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default PhoneVerificationTooltip;
