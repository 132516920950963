import React from 'react';
import PropTypes from 'prop-types';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import Tooltip from '@mui/material/Tooltip';
import { useGetUser } from '../../hooks';

const PERMISSION = 'debtors.view_debtorratificationinformation';

const DebtorRatificationInformationTooltip = ({ debtor }) => {
  const user = useGetUser();
  if (!user.permissions.includes(PERMISSION)) return null;

  if (!debtor) return null;

  const { ratificationInformation } = debtor;
  if (!ratificationInformation) return null;

  const { comment } = ratificationInformation;
  const tooltipTitle = `Deudor no ratifica: ${comment}`;

  return (
    <Tooltip title={tooltipTitle}>
      <PhoneDisabledIcon sx={{ fontSize: 18 }} />
    </Tooltip>
  );
};

DebtorRatificationInformationTooltip.propTypes = {
  debtor: PropTypes.shape({
    ratificationInformation: PropTypes.shape({
      comment: PropTypes.string,
    }),
  }),
};

DebtorRatificationInformationTooltip.defaultProps = {
  debtor: null,
};

export default DebtorRatificationInformationTooltip;
