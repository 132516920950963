import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const DotsStatusTimeline = ({ statusSteps }) => (
  <Stack direction="row" spacing={0.5}>
    {statusSteps.map((step) => (
      <Box
        key={`${step.id}-dot`}
        sx={{
          width: 8,
          height: 8,
          backgroundColor: (step.finished || step.actualState) ? 'primary.main' : 'gray.A300',
          borderRadius: '50%',
        }}
      />
    ))}
  </Stack>
);

DotsStatusTimeline.propTypes = {
  statusSteps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      datetime: PropTypes.instanceOf(Date),
    }),
  ).isRequired,
};

export default DotsStatusTimeline;
